import { Navigate } from 'react-router-dom';

import { useSpecification } from '@/shared/hooks/useSpecification';
import type { RouteObjectWithBreadcrumbs } from '@/shared/lib/react-router';
import { DorimPromoPaths } from '@/shared/lib/react-router';

import { useSpecificationIdFromPathParams } from '../lib';
import { Layout } from './layout';
import { PlanogramTasksPageRoute } from './planogram';
import { ProcurementPlanPageRoute } from './procurement-plan';
import { SalesPlanPageRoute } from './sales-plan';

const RedirectToCorrectPath = () => {
  const specificationId = useSpecificationIdFromPathParams();
  const stringifiedSpecificationId = String(specificationId);
  const { data, isLoading } = useSpecification({ specificationId });

  const { has_display_tasks, has_purchase_drugs, has_sale_drugs } = data?.agreement ?? {};

  const procurementPlanPath = DorimPromoPaths.generateSpecificationDetailProcurementPlanPath({
    specificationId: stringifiedSpecificationId,
  });
  const planogramPath = DorimPromoPaths.generateSpecificationDetailPlanogramPath({
    specificationId: stringifiedSpecificationId,
  });
  const salesPlanPath = DorimPromoPaths.generateSpecificationDetailSalesPlanPath({
    specificationId: stringifiedSpecificationId,
  });

  const defaultPath = has_purchase_drugs
    ? procurementPlanPath
    : has_display_tasks
      ? planogramPath
      : has_sale_drugs
        ? salesPlanPath
        : DorimPromoPaths.SPECIFICATIONS_ROOT_PATH;

  if (isLoading) {
    return null;
  }

  return <Navigate to={defaultPath} replace />;
};

export const SpecificationPageRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.SPECIFICATION_DETAIL_PATH,
  element: <Layout />,
  children: [
    {
      index: true,
      element: <RedirectToCorrectPath />,
    },
    ProcurementPlanPageRoute,
    PlanogramTasksPageRoute,
    SalesPlanPageRoute,
  ],
};
