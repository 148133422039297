import type { ContractorTypeAlias } from '@/shared/constants/contractor-types.constants';
import { httpClient } from '@/shared/lib/axios';

import type { PaginatedList, PaginationParams } from '../types/pagination.types';
import type { Contact, Contractor, ContractorOpeningHours, DistributorInfo } from './contractors.types';

export type FetchDistributorInfoParams = {
  distributor_id: number;
};

export type FetchContractorContactsParams = {
  contractor_id: number;
};

export type FetchContractorsParams = PaginationParams & {
  contractor_type_aliases?: readonly ContractorTypeAlias[];
  query?: string;
};

export type SearchContractorsParams = PaginationParams & {
  query?: string;
  contractor_type_ids?: number[];
};

export type ContractorCreateFormData = {
  contractor_type_id: number;
  name: string;
  legal_status_id: number;
  country_id: string;
  country_name: string;
  description?: string;
  parent_id?: number;
  tin?: number;
  note?: string;
  logo_url_token?: string;
  activity_status_id?: number;
};

export type ContractorUpdateFormData = ContractorCreateFormData & {
  record_status_id?: number;
};

export type UpdatePharmacyDataParams = {
  contractorId: number;
  geolocation_latitude: number;
  geolocation_longitude: number;
  location_description: string;
  opening_hours: Partial<ContractorOpeningHours>;
};

export type ContractorRegisterDataParams = {
  tin: number;
};

export type FetchContractorParams = {
  contractorId: number;
};

export type ContractorUpdataParams = {
  contractorId: number;
  formData: ContractorUpdateFormData;
};
// ----------------------------------------------------------------------

export const ContractorsApi = {
  fetchDistributorInfo: async ({ distributor_id }: FetchDistributorInfoParams) => {
    const { data } = await httpClient.get<DistributorInfo>(`distributors/${distributor_id}`);

    return data;
  },

  fetchContractorContacts: async (params: FetchContractorContactsParams) => {
    const { data } = await httpClient.get<PaginatedList<'items', Contact>>('contacts', { params });

    return data;
  },

  fetchContractor: async ({ contractorId }: FetchContractorParams) => {
    const { data } = await httpClient.get<Contractor>(`contractors/${contractorId}`);

    return data;
  },

  fetchContractors: async (params?: FetchContractorsParams) => {
    const { data } = await httpClient.get<PaginatedList<'items', Contractor>>('contractors', {
      params,
    });

    return data;
  },

  deleteContractor: (contractorId: number) => httpClient.delete<void>(`contractors/${contractorId}`),

  createContractor: async (formData: ContractorCreateFormData) => {
    const { data } = await httpClient.post<Contractor>('contractors', formData);

    return data;
  },

  updateContractor: async ({ contractorId, formData }: ContractorUpdataParams) => {
    const { data } = await httpClient.put<Contractor>(`contractors/${contractorId}`, formData);

    return data;
  },

  updatePharmacyData: async ({ contractorId, ...params }: UpdatePharmacyDataParams) => {
    const { data } = await httpClient.put<Contractor>(`contractors/${contractorId}/pharmacy-data`, params);

    return data;
  },
};
