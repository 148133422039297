import { omit } from 'lodash';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import type { AccountingSystemAlias } from '@/api/domains/auto-selection.types';

type State = {
  isConfirmationDialogOpen: boolean;
  isImportFileModalOpen: boolean;

  necessityFile: File | null;
  selectedAccountingSystem: AccountingSystemAlias | null;
  outOfStockPeriod: string[] | null;
};

type Actions = {
  openConfirmationDialog: () => void;
  closeConfirmationDialog: () => void;

  openImportFileModal: () => void;
  closeImportFileModal: () => void;

  setNecessityDrugsFile: (file: File) => void;
  resetNecessityDrugsFile: () => void;

  setSelectedAccountingSystem: (accountingSystem: AccountingSystemAlias) => void;
  resetSelectedAccountingSystem: () => void;

  setOutOfStockPeriodPeriod: (period: string[] | null) => void;
};

type NecessityFileStore = State & {
  actions: Actions;
};

const initialState: State = {
  isConfirmationDialogOpen: false,
  isImportFileModalOpen: false,

  necessityFile: null,
  selectedAccountingSystem: null,
  outOfStockPeriod: null,
};

const useNecessityFileStore = create<NecessityFileStore>()(
  devtools(
    set => ({
      ...initialState,

      actions: {
        openConfirmationDialog: () => set({ isConfirmationDialogOpen: true }, false, 'openConfirmationDialog'),
        closeConfirmationDialog: () => set({ isConfirmationDialogOpen: false }, false, 'closeConfirmationDialog'),

        openImportFileModal: () => set({ isImportFileModalOpen: true }, false, 'openImportFileModal'),
        closeImportFileModal: () => set({ isImportFileModalOpen: false }, false, 'closeImportFileModal'),

        setNecessityDrugsFile: necessityFile => set({ necessityFile }, false, 'setNecessityDrugsFile'),
        resetNecessityDrugsFile: () => set({ necessityFile: null }, false, 'resetNecessityDrugsFile'),
        setOutOfStockPeriodPeriod: outOfStockPeriod => set({ outOfStockPeriod }, false, 'setOutOfStockPeriodPeriod'),

        setSelectedAccountingSystem: selectedAccountingSystem =>
          set({ selectedAccountingSystem }, false, 'setSelectedAccountingSystem'),
        resetSelectedAccountingSystem: () =>
          set({ selectedAccountingSystem: null }, false, 'resetSelectedAccountingSystem'),
      },
    }),
    {
      name: 'necessity-file-store',
      enabled: import.meta.env.DEV,
    },
  ),
);

export const useNecessityFile = () => useNecessityFileStore(state => state.necessityFile);
export const useSelectedAccountingSystem = () => useNecessityFileStore(state => state.selectedAccountingSystem);
export const useOutOfStockPeriod = () => useNecessityFileStore(state => state.outOfStockPeriod);
export const useIsImportFileModalOpen = () => useNecessityFileStore(state => state.isImportFileModalOpen);
export const useIsConfirmationDialogOpen = () => useNecessityFileStore(state => state.isConfirmationDialogOpen);
export const useNecessityFileStoreActions = () => useNecessityFileStore(state => state.actions);

export const resetNecessityFileStore = (omitKeys?: Array<keyof State>) =>
  useNecessityFileStore.setState(omitKeys ? omit(initialState, omitKeys) : initialState);
