import { useFormContext } from 'react-hook-form';
import { useUpdateEffect } from 'react-use';
import { FormControl } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import type { LegalStatus } from '@/api/domains/contractors.types';
import { RoundedButton } from '@/components/buttons';
import { CustomFormLabel, RHFIntegerTextField, RHFTextField } from '@/components/form-controls';
import type { SignUpCompany } from '@/pages/auth/sign-up/store/signUp.store';
import { isSafeNumeric } from '@/shared/helpers/strings.helpers.ts';

import { type FormValues } from '..';

// ----------------------------------------------------------------------

export type CompanyFormFields = {
  legalStatus: LegalStatus | null;
  companyName: string | null;
  tin: number | null;
};

export const getDefaultCompanyFormValues = (shouldPrefillForm: boolean, storedCompanyData: Partial<SignUpCompany>) => ({
  legalStatus: shouldPrefillForm ? storedCompanyData.legalStatus : null,
  companyName: shouldPrefillForm ? storedCompanyData.companyName : '',
  tin: shouldPrefillForm ? storedCompanyData.tin : null,
});

// ----------------------------------------------------------------------

type CompanyInfoFormProps = {
  handleLoadData: () => Promise<void>;
  isLoading: boolean;
};

const TIN_MAX_LENGTH = 9;

export const CompanyInfoForm = (props: CompanyInfoFormProps) => {
  const {
    watch,
    formState: { dirtyFields },
    resetField,
  } = useFormContext<FormValues>();
  const { handleLoadData, isLoading } = props;

  const tin = watch('tin');
  const legalStatus = watch('legalStatus');

  useUpdateEffect(() => {
    if (dirtyFields.tin) {
      resetField('companyName');
      resetField('legalStatus');
    }
  }, [tin]);

  return (
    <Grid container rowSpacing={3} columnSpacing={2}>
      <Grid container mobile={12} tablet={12} alignItems="flex-end">
        <Grid mobile={12} tablet={6}>
          {/* TIN */}
          <FormControl fullWidth>
            <CustomFormLabel isRequired htmlFor="tin">
              ИНН
            </CustomFormLabel>
            <RHFIntegerTextField
              isAllowed={({ value }) => isSafeNumeric(value) && value.length <= TIN_MAX_LENGTH}
              fullWidth
              name="tin"
              id="tin"
              variant="filled"
            />
          </FormControl>
          {/* TIN END */}
        </Grid>
        <Grid mobile={6} tablet={6}>
          <RoundedButton disabled={!tin} loading={isLoading} onClick={handleLoadData}>
            Получить данные
          </RoundedButton>
        </Grid>
      </Grid>
      <Grid mobile={12} tablet={6}>
        {/* LEGAL STATUS */}
        <FormControl fullWidth>
          <CustomFormLabel isRequired htmlFor="legalStatus">
            Форма собственности
          </CustomFormLabel>

          <RHFTextField
            InputProps={{ value: legalStatus?.name || '' }}
            isClearable={false}
            fullWidth
            name="legalStatus"
            id="legalStatus"
            variant="filled"
            disabled
          />
        </FormControl>
        {/* LEGAL STATUS END */}
      </Grid>

      <Grid mobile={12} tablet={6}>
        {/* NAME */}
        <FormControl fullWidth>
          <CustomFormLabel isRequired htmlFor="companyName">
            Название
          </CustomFormLabel>
          <RHFTextField isClearable={false} fullWidth name="companyName" id="companyName" variant="filled" disabled />
        </FormControl>
        {/* NAME END */}
      </Grid>
    </Grid>
  );
};
