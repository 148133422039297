import { Link } from 'react-router-dom';
import { Divider, Tab, Tabs } from '@mui/material';
import { first } from 'lodash';

import { HeaderTabs } from '@/layouts/Header';
import { useActiveAutoSelection } from '@/pages/product-selection/auto/shared/hooks/useActiveAutoSelection';
import { ProductSelectionPaths } from '@/shared/lib/react-router';

import { useProductSelectionLayoutTabs } from './useProductSelectionLayoutTabs';

export const ProductSelectionLayoutTabs = () => {
  const { currentTab, manualTabValue, specialOffersTabValue, autoTabValue, boundDrugsValue } =
    useProductSelectionLayoutTabs();

  const { data: autoSelectionInfo } = useActiveAutoSelection({ select: first });
  const autoSelectionId = autoSelectionInfo?.id;

  return (
    <HeaderTabs>
      <Tabs value={currentTab} textColor="primary" indicatorColor="primary" data-testid="product-selection-tabs">
        <Tab
          component={Link}
          to={ProductSelectionPaths.MANUAL_SELECTION_PATH}
          value={manualTabValue}
          label="Ручной подбор"
          data-testid="product-selection-manual-selection-tab"
        />
        <Tab
          component={Link}
          to={ProductSelectionPaths.SPECIAL_OFFERS_SELECTION_PATH}
          value={specialOffersTabValue}
          label="Все акции"
          data-testid="product-selection-special-offers-tab"
        />
        <Divider orientation="vertical" flexItem sx={{ my: 1, marginRight: 1 }} />
        <Tab
          component={Link}
          to={ProductSelectionPaths.AUTO_SELECTION_PATH}
          value={autoTabValue}
          label="Автоподбор"
          data-testid="product-selection-auto-selection-tab"
        />
        {autoSelectionId && (
          <Tab
            component={Link}
            to={ProductSelectionPaths.BOUND_DRUGS_PATH}
            value={boundDrugsValue}
            label="Привязанные товары"
            data-testid="product-selection-bounded-drugs-tab"
          />
        )}
        {autoSelectionId && <Divider orientation="vertical" flexItem sx={{ m: 1 }} />}
      </Tabs>
    </HeaderTabs>
  );
};
