import { Stack, Typography, type StackProps, type TypographyProps } from '@mui/material';

export type HeaderTitleProps = Omit<StackProps, 'component' | 'direction'> & TypographyProps;

export const HeaderTitle = (props: HeaderTitleProps) => (
  <Typography
    component={Stack}
    direction="row"
    alignItems="center"
    spacing={1}
    mr="auto"
    pl={1}
    variant="Title/medium"
    color="primary"
    data-testid="header-title"
    {...props}
  />
);
