import { Box, Stack, styled, Typography } from '@mui/material';
import type { Merge } from 'type-fest';

import { DescriptionBase, type DescriptionBaseProps } from './DescriptionBase';
import { Forecast } from './Forecast';
import { TotalAmount } from './TotalAmount';

type DescriptionProps = Merge<
  Pick<DescriptionBaseProps, 'hasDivider' | 'description'>,
  {
    paymentTitle: string;
    paymentValue: number;
    minBonus?: number;
    maxBonus?: number;
  }
>;

const Card = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1.5, 2),
}));

const ForecastCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.secondary['+2'],
}));

const PaymentCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.tetriary['+2'],
}));

const DescriptionContent = (props: Omit<DescriptionProps, 'hasDivider' | 'description'>) => {
  const { paymentTitle, paymentValue, minBonus, maxBonus } = props;

  return (
    <Stack direction="row" spacing={2}>
      {minBonus != null && maxBonus != null && (
        <ForecastCard alignSelf="flex-end">
          <Typography variant="Body/small" color="neutral.700">
            Прогноз выплаты
          </Typography>
          <Forecast from={minBonus} to={maxBonus} />
        </ForecastCard>
      )}
      <PaymentCard alignSelf="flex-end">
        <Typography variant="Body/small" color="neutral.700">
          {paymentTitle}
        </Typography>
        <TotalAmount paymentSum={paymentValue} />
      </PaymentCard>
    </Stack>
  );
};

export const Description = (props: DescriptionProps) => {
  const { hasDivider, description, ...contentProps } = props;

  return (
    <DescriptionBase hasDivider={hasDivider} description={description}>
      <DescriptionContent {...contentProps} />
    </DescriptionBase>
  );
};
