import type { UseFetchContractorByTin } from '@/pages/auth/sign-up/hooks/useSignupData.ts';
import { paramsToQueryKey } from '@/shared/lib/react-query';

import type {
  FetchContractorContactsParams,
  FetchContractorParams,
  FetchContractorsParams,
  FetchDistributorInfoParams,
} from './contractors.api';

const PREFIX = 'contractors';

export const ContractorsQueryKeys = {
  root: [PREFIX],

  getContractorContactsKey: (params: FetchContractorContactsParams) => paramsToQueryKey([PREFIX, 'contacts'], params),
  getDistributorInfoKey: (params: Partial<FetchDistributorInfoParams>) =>
    paramsToQueryKey([PREFIX, 'distributor-info'], params),

  getContractorsTreeKey: (params?: FetchContractorsParams) => paramsToQueryKey([PREFIX, 'list'], params),

  contractorChildrenKey: [PREFIX, 'contractor-children'],

  getContractorKey: (params?: Partial<FetchContractorParams>) => paramsToQueryKey([PREFIX, 'view'], params),

  getContractorByTinKey: (params?: Partial<UseFetchContractorByTin>) => paramsToQueryKey([PREFIX, 'tin'], params),
} as const;
