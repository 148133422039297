import { Stack, Typography } from '@mui/material';

import { formatNumberByThousands } from '@/shared/helpers/format.helpers';

type ForecastProps = {
  from: number;
  to: number;
};

export const Forecast = (props: ForecastProps) => {
  const { from, to } = props;
  return (
    <Stack direction="row" spacing={1} justifyContent="flex-end">
      <Typography variant="Headline/small" color="neutral.700">
        от
      </Typography>
      <Typography variant="Headline/Bold/small">{formatNumberByThousands(from)}</Typography>
      <Typography variant="Headline/small" color="neutral.700">
        UZS
      </Typography>
      <Typography variant="Headline/small" color="neutral.700">
        до
      </Typography>
      <Typography variant="Headline/Bold/small">{formatNumberByThousands(to)}</Typography>
      <Typography variant="Headline/small" color="neutral.700">
        UZS
      </Typography>
    </Stack>
  );
};
