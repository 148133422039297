import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import type { LegalStatus } from '@/api/domains/contractors.types';

import type { Address } from './signup.types';

type SignUpUser = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  password: string;
  isAgree: boolean;
};

export type SignUpCompany = {
  companyName: string | null;
  legalStatus: LegalStatus | null;
  tin: number | null;
} & Address;

export type SignUpPharmacy = {
  name: string;
} & Address;

type SignUpState = {
  user: SignUpUser;
  company: SignUpCompany;
  pharmacy: SignUpPharmacy;
};

type SignUpActions = {
  setUserData: (user: Partial<SignUpState['user']>) => void;
  setCompanyData: (company: Partial<SignUpState['company']>) => void;
  setPharmacyData: (pharmacy: Partial<SignUpState['pharmacy']>) => void;

  resetSignUpData: () => void;
};

export type SignUpStore = SignUpState & SignUpActions;

const initialState: SignUpState = {
  user: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    password: '',
    isAgree: false,
  },
  company: {
    legalStatus: null,
    companyName: '',
    tin: null,
    region: null,
    city: null,
    cityDistrict: null,
    streetType: null,
    street: '',
    house: '',
    building: '',
    apartment: '',
    phoneNumber: '',
    postalCode: '',
  },
  pharmacy: {
    name: '',
    region: null,
    city: null,
    cityDistrict: null,
    streetType: null,
    street: '',
    house: '',
    building: '',
    apartment: '',
    phoneNumber: '',
    postalCode: '',
  },
};

const storeName = 'dorim-price:sign-up';
const useSignUpStore = create<SignUpStore>()(
  devtools(
    persist(
      set => ({
        ...initialState,

        // Actions
        setUserData: user => set(state => ({ user: { ...state.user, ...user } }), false, 'setUserData'),
        setCompanyData: company =>
          set(state => ({ company: { ...state.company, ...company } }), false, 'setCompanyData'),
        setPharmacyData: pharmacy =>
          set(state => ({ pharmacy: { ...state.pharmacy, ...pharmacy } }), false, 'setPharmacyData'),

        resetSignUpData: () => set(initialState, false, 'resetSignUpData'),
      }),
      {
        name: storeName,
      },
    ),
    {
      name: storeName,
    },
  ),
);

// Selectors for state
const selectSignUpState = (state: SignUpStore) => ({
  userData: state.user,
  companyData: state.company,
  pharmacyData: state.pharmacy,
});
const selectUserData = (state: SignUpStore) => state.user;
const selectCompanyData = (state: SignUpStore) => state.company;
const selectPharmacyData = (state: SignUpStore) => state.pharmacy;

// Selectors for actions
const selectSignUpActions = (state: SignUpStore) => ({
  setUserData: state.setUserData,
  setCompanyData: state.setCompanyData,
  setPharmacyData: state.setPharmacyData,

  resetSignUpData: state.resetSignUpData,
});

// Hooks
export const useSignUpStoreState = () => useSignUpStore(selectSignUpState);
export const useSignUpStoreUserData = () => useSignUpStore(selectUserData);
export const useSignUpStoreCompanyData = () => useSignUpStore(selectCompanyData);
export const useSignUpStorePharmacyData = () => useSignUpStore(selectPharmacyData);

export const useSignUpStoreActions = () => useSignUpStore(selectSignUpActions);
